<script setup>
  import { onMounted } from 'vue';
  import { successGetCurrentSysInfo } from '@/scripts/QuasarNotify.js';

  // - 監聽 command + i 或 ctrl + i
  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.keyCode === 73) {
      successGetCurrentSysInfo();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <router-view />
</template>
