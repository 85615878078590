import { defineStore } from 'pinia';

export const useLoginTimeout = defineStore({
  id: 'status',
  state: () => ({
    value: false,
  }),
  actions: {
    statusDialog(status) {
      this.value = status;
    },
  },
});
