import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useReportError = defineStore('reportError', () => {
  const dialog = ref(false);

  const errorMsg = ref('');

  const clearErrorMsg = () => {
    errorMsg.value = '';
  };

  return { dialog, errorMsg, clearErrorMsg };
});
