<script setup>
  import { ref } from 'vue';
  import { useReportError } from '@/stores/report-error';
  import { copyToClipboard } from 'quasar';

  const reportError = useReportError();

  const dialog = ref(true);

  const openReportErrorForm = () => {
    reportError.dialog = false;
    window.open('https://forms.gle/bLZkkf7YbjPpBzQw9');
  };
</script>

<template>
  <q-dialog v-model="dialog" persistent>
    <q-card>
      <div class="alert alert-danger">
        <div class="dialog__title">
          <q-icon name="mdi-alert"> </q-icon>
          <span>&nbsp;&nbsp;發生錯誤</span>
        </div>
      </div>
      <q-card-section class="dialog__content">
        <span>遇到預期外的錯誤，請回報錯誤狀況給 IT 部門</span>
      </q-card-section>
      <q-card-section class="dialog__content">
        <q-field
          type="textarea"
          filled
          label="錯誤資訊"
          stack-label
          style="max-height: 120px; overflow-y: auto"
        >
          <template #control>
            <div style="width: 120px">
              {{ reportError.errorMsg }}
            </div>
          </template>
        </q-field>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          v-close-popup
          color="grey"
          label="取消"
          style="width: 40%"
          @click="reportError.dialog = false"
        />
        <div style="width: 10%" />
        <q-btn
          v-close-popup
          color="primary"
          label="回報錯誤"
          style="width: 40%"
          @click="
            openReportErrorForm();
            copyToClipboard(reportError.errorMsg);
            reportError.clearErrorMsg();
          "
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
