import { defineStore } from 'pinia';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    username: '',
    empId: '',
    empName: '',
    taskCount: 0,
  }),
  actions: {
    storeUserData(user) {
      // 將用戶數據存儲在本地存儲中
      this.username = user.username;
      this.empId = user.empId;
      this.empName = user.empName;

      // 將用戶數據轉換為 JSON 格式並存儲在本地存儲中
      const userData = {
        username: this.username,
        empId: this.empId,
        empName: this.empName,
        taskCount: this.taskCount,
      };

      // 將權限等級存儲在本地存儲中
      localStorage.setItem('user', JSON.stringify(userData));
    },
  },
});
